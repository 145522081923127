globalThis["__sentryRewritesTunnelPath__"] = "/monitoring";
globalThis["SENTRY_RELEASE"] = {"id":"TmkWVa38jtt323P6f4D6O"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const dsn =
  process.env.NEXT_PUBLIC_SENTRY_DSN ||
  "https://0da239a5a178e0b5e45719e5ef34e677@o4505918201528320.ingest.sentry.io/4505918203887616";

// Initial Sentry setup
Sentry.init({
  dsn,

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // Initial integrations without Replay
  integrations: [Sentry.browserTracingIntegration()],
});

// Conditionally add the Replay integration
// @ts-ignore
// if (typeof document.adoptedStyleSheets === "undefined") {
//     Sentry.addIntegration(
//         Sentry.replayIntegration({
//             maskAllText: true,
//             blockAllMedia: true,
//         }),
//     );
// } else {
//     console.warn("adoptedStyleSheets is already defined, avoiding Replay integration");
// }
